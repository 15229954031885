<template>
  <section class="widget">
    <div>
      <!-- <button v-b-modal.modallg>Open Modal</button> -->
      <div class="row mt-2 d-flex jusitfy-content-between">
        <div class="col">
          <button
            id="create-campaing"
            class="add btn btn-primary font-weight-bold todo-list-add-btn ml-0"
            @click="create"
          >
            Create Campaign
          </button>
        </div>
        <b-form-group
          class="select col-3 align-center ml-audo"
          label-for="input11"
        >
          <b-form-select
            :options="options"
            v-model="selectedStatus"
            size="sm"
            @change="updateStatus"
          />
        </b-form-group>
      </div>
      <div class="row mt-3">
        <div class="col-lg-3 col-md-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-center">
                <!-- <i
                  class="icon-clock icon-lg text-primary d-flex align-items-center"
                ></i> -->
                <div class="d-flex flex-column">
                  <div class="d-flex flex-column text-dark text-center">
                    <p class="mb-0">Сompanies created</p>
                    <h4 class="font-weight-bold mt-2 mb-0" v-if="items">
                      {{ items.length }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 grid-margin stretch-card"
          @click="$router.push('/credits')"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-center">
                <!-- <i
                  class="icon-credit-card icon-lg text-success d-flex align-items-center"
                ></i> -->
                <div class="d-flex flex-column">
                  <div class="d-flex flex-column text-dark text-center">
                    <p class="mb-0">Remaining Delivery Credits</p>
                    <h4
                      class="font-weight-bold mt-2 mb-0"
                      v-if="$store.state.user && $store.state.user.credits"
                    >
                      {{ $store.state.user.credits }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-center">
                <!-- <i
                  class="icon-clock icon-lg text-primary d-flex align-items-center"
                ></i> -->
                <div class="d-flex flex-column">
                  <div class="d-flex flex-column text-dark text-center">
                    <p class="mb-0">Last week attempts</p>
                    <h4 class="font-weight-bold mt-2 mb-0" v-if="data">
                      {{ data.last_week_attempts }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-center">
                <!-- <i
                  class="icon-phone-message-outline icon-lg text-primary d-flex align-items-center"
                ></i> -->
                <div class="d-flex flex-column">
                  <div class="d-flex flex-column text-dark text-center">
                    <p class="mb-0">Last week drops</p>
                    <h4 class="font-weight-bold mt-2 mb-0" v-if="data">
                      {{ data.last_week_drops }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="items">
        <div
          class="col-md-4 stretch-card grid-margin"
          v-for="item in items"
          :key="item.id"
        >
          <div class="card bg-info card-img-holder">
            <div class="card-body">
              <p class="text-white" @click="redirectToReporting(item.id)">{{ getTime(item.created) }}</p>
              <img
                src="@/assets/images/dashboard/circle.svg"
                class="card-img-absolute"
                alt="circle-image"
              />
              <h4
                class="d-flex justify-content-between align-items-center font-weight-normal mb-3 text-white"
                @click="redirectToReporting(item.id)"
              >
                {{ getStatus(item.status)
                }}{{ item.status == 0 ? `(${getTime(item.scheduled)})` : null }}
                <b-nav-item-dropdown
                  right
                  noCaret
                  class="preview-list d-md-flex"
                >
                  <template
                    slot="button-content"
                    class="p-0 m-0 d-flex align-items-center"
                  >
                    <button
                      class="btn p-0 text-white"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                    </button>
                  </template>
                  <b-dropdown-item @click="goToCall(item.id)">
                    Call Log
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="stopCompany(item.id)"
                    v-if="item.status == 1"
                    >Stop</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="#"
                    @click="deleteConfirm(item.id)"
                    v-if="admin"
                    >Delete</b-dropdown-item
                  >
                  <!-- <b-dropdown-item href="#"
                    >Something else here</b-dropdown-item
                  > -->
                </b-nav-item-dropdown>
              </h4>
              <h3 class="mb-5 text-white" @click="redirectToReporting(item.id)">
                {{ item.name ? item.name : "Noname" }}
              </h3>
              <router-link
                :to="{ name: 'reporting', params: { id: item.id } }"
                class="card-text text-white"
                v-if="item.processed_dnis"
              >
                <i class="fa fa-send mr-1" />Recipients:
                {{ item.processed_dnis }} / {{ item.total_dnis }}
              </router-link>
              <router-link
                class="card-text text-white"
                :to="{
                  name: 'audio-manage',
                  params: { name: getMedia(item.media_id) },
                }"
                v-if="getMedia(item.media_id)"
              >
                <i class="fa fa-microphone mr-2" />{{ getMedia(item.media_id) }}
              </router-link>
              <router-link
                :to="{ name: 'groups', params: { name: item.groups } }"
                class="card-text text-white"
                v-if="item.groups"
              >
                <i class="fa fa-users mr-1" />{{
                  item.groups.split(",").join(", ")
                }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "widget",
  data() {
    return {
      options: [
        { value: -1, text: "All" },
        { value: 0, text: "Scheduled" },
        { value: 1, text: "In progress" },
        { value: 2, text: "Completed" },
        { value: 3, text: "Interrupted" },
        { value: 4, text: "Failed to start" },
      ],
      selectedStatus: -1,
      data: null,
    };
  },
  async mounted() {
    await this.$store.dispatch("loadUserInfo");
    this.loadData();
    this.$store.dispatch("loadItems");
    this.$store.dispatch("loadFiles", "info");
    this.$store.commit("updateLoading", true);
  },
  computed: {
    ...mapGetters(["groups", "audios", "loading", "admin", "items"]),
  },
  methods: {
    create() {
        this.$router.push({ name: "create-campaign" });
    },
    onDelete(id) {
      this.$axios
        .post(`/admin/campaigns/delete/${id}`)
        .then((res) => {
          this.$swal.fire("Campaign deleted!", res.data.message, "success");
          this.$store.dispatch("loadItems");
        })
        .catch((err) => {
          this.$swal.fire("Error", err.response.data.error, "error");
        });
    },
    goToCall(id) {
      this.$router.push({ name: "call-logs", params: { camp: id } });
    },
    redirectToReporting(id) {
      this.$router.push({name: "reporting", params: { id: id }});
    },
    stopCompany(id) {
      this.$axios
        .post(`${this.admin}/campaigns/stop/${id}`)
        .then((res) => {
          this.$swal.fire("Campaign stopped!", res.data.message, "success");
          this.$store.dispatch("loadItems");
        })
        .catch((err) => {
          this.$swal.fire("Error", err.response.data.error, "error");
        });
    },
    loadData() {
      this.$axios("/dashboard")
        .then((res) => {
          console.log(res);
          this.data = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatus(status) {
      let value = null;
      switch (status) {
        case 0: {
          value = "Scheduled";
          break;
        }
        case 1: {
          value = "In progress";
          break;
        }
        case 2: {
          value = "Completed";
          break;
        }
        case 3: {
          value = "Interrupted";
          break;
        }
        case 4: {
          value = "Failed to start";
          break;
        }
      }
      return value;
    },
    getTime(time) {
      return this.$moment.unix(time).format("MM-DD-YYYY HH:mm:ss");
    },
    getMedia(id) {
      let mediaName;
      this.audios.forEach((el) => {
        if (el.media_id == id) {
          mediaName = el.text;
        }
      });
      return mediaName;
    },
    deleteConfirm(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.onDelete(id);
          }
        });
    },
    updateStatus() {
      console.log(this.selectedStatus);
      if (this.selectedStatus !== -1) {
        this.$axios
          .get(`${this.admin}/campaigns/${this.selectedStatus}`)
          .then((res) => {
            this.$store.commit("updateItems", res.data.campaigns);
          });
      } else {
        this.$store.dispatch("loadItems");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card .card-body {
  padding: 1rem 1.875rem 1.25rem;
  cursor: pointer;
}
.select {
  height: 35px;
  .bv-no-focus-ring,
  select {
    height: 35px;
  }
}
.card-text {
  font-size: 15px;
  width: 100%;
  display: block;
  position: relative;
  z-index: 100;
}
</style>